import { Button, Container, Grid } from '@mui/material';
import React from 'react';
import My_Graduation from '../assets/images/My_Graduation.png';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function HeroBanner() {
  return (
    <>
      <br />
      <br />
      <Container>
        <Grid container sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' } }}>
          <Grid
            item
            xs={12}
            sm={8}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 1, py: 8 }}
          >
            <span style={{ fontSize: '18px' }}>Hello, my name is</span>
            <div style={{ fontSize: '52px' }}>Rizki Delaga Prasetya</div>
            <div style={{ maxWidth: '600px', fontSize: '18px' }}>
              Someone passionate about information technology developments. I specialize in{' '}
              <strong>Front-End Web Development</strong>, <strong>Designer UI/UX</strong>, and{' '}
              <strong>Programming</strong>.
            </div>
            <div style={{ display: 'flex', gap: '14px' }}>
              <Link
                to="https://drive.google.com/file/d/120M4is0y-oppbdRbUITUlBgCFCvtYBvP/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="outlined" size="medium" color="primary" sx={{ width: 'fit-content' }}>
                  Download CV
                </Button>
              </Link>
              <HashLink smooth to={'#contact'}>
                <Button variant="contained" size="medium" color="primary" sx={{ width: 'fit-content' }}>
                  Hire Me
                </Button>
              </HashLink>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              width={'100%'}
              height={'auto'}
              src={My_Graduation}
              // src="https://purepng.com/public/uploads/large/iron-man-g3a.png"

              alt=""
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
