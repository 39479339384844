import { Box, Container, Grid } from '@mui/material';
import React from 'react';
import Union from '../assets/images/Union.png';

export default function About() {
  return (
    <Box id="about" sx={{ bgcolor: '#ffffff', py: 10 }}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={3.5} md={4}>
            <h1
              style={{
                position: 'relative',
                zIndex: 1,
                margin: 0,
                borderLeft: '6px solid #ff4500',
                paddingLeft: '5px',
              }}
            >
              About Me
            </h1>
            <img
              width={'80'}
              src={Union}
              style={{ position: 'relative', top: -25, left: 70, marginBottom: '-25px' }}
              alt=""
            />
          </Grid>
          <Grid item xs={12} sm={8.5} md={8}>
            <div>
              Fresh Graduate from Institut Teknologi Telkom Purwokerto with a Bachelor's degree in Software Engineering.
              I am enthusiastic about technology and information. During college, I published three journals,
              participated in competitions on campus, and produced several software projects. I have expertise in
              software development, especially front-end website development. I am quick to learn, adaptable, and can
              collaborate with a team, making me ready to contribute in a professional environment. Currently, I am
              looking for opportunities to be part of digital innovation development and am committed to building a
              strong foundation as a front-end developer.
            </div>
            <br />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Box
                sx={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  borderBottom: 3,
                  borderColor: 'primary.main',
                  width: 'fit-content',
                }}
              >
                Education
              </Box>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong>Institut Teknologi Telkom Purwokerto</strong>
                <span style={{ textAlign: 'end', color: 'gray' }}>2019 - 2023</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Bachelor’s of Software Engineering (S. Kom)</span>
                <span style={{ textAlign: 'end', color: 'gray' }}>GPA: 3.76/4.00 (Cumlaude)</span>
              </div>
              <div>
                Bachelor's Thesis: Frontend Design of a Laundry Business Website Using User-Centered Design Method (Case
                Study: Lulu ‘N Be Luxury Laundry)
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
