import { Box, Button, Container, Grid } from '@mui/material';
import React from 'react';
import WaveDownVector from '../assets/images/WaveDownVector.png';
import WaveUpVector from '../assets/images/WaveUpVector.png';
import KulinerJowo from '../assets/images/Kuliner Jowo Screen Poster.png';
import Localla from '../assets/images/Localla Screen Poster.png';
import Diagnoseit from '../assets/images/Diagnoseit Screen.png';
import LuluNBeLuxuryLaundry from '../assets/images/LuluNBeLuxuryLaundry Screen Poster.png';
import style from './HomeStyles.module.css';
import { Link } from 'react-router-dom';

export default function Projects() {
  const projectList = [
    {
      image: LuluNBeLuxuryLaundry,
      title: `Lulu 'N Be Luxury Laundry | Laundry Business Website`,
      description:
        ' Laundry order management website. Admin can manage the entire laundry process, while customers can make independent orders and monitor laundry status',
      stack: [],
      sourceCodeLink: 'https://github.com/RizkiDelaga/lulunbeluxurylaundry',
      previewLink: 'https://lulunbeluxurylaundry.com/',
    },
    {
      image: Localla,
      title: 'Localla',
      description: 'Online marketplace site for selling second-hand products specifically for local fashion',
      stack: [],
      sourceCodeLink: 'https://github.com/RizkiDelaga/Localla-App',
      previewLink: 'https://localla-app.vercel.app/',
    },
    {
      image: KulinerJowo,
      title: 'Kuliner Jowo',
      description:
        '"Kuliner Jowo" is an Android application that displays a list of typical Javanese culinary delights. This application displays detailed cooking information, ingredients, how to make it, and video tutorials.',
      stack: [],
      sourceCodeLink: 'https://github.com/RizkiDelaga/Aplikasi-Kuliner-Jowo',
      previewLink: 'https://github.com/RizkiDelaga/Aplikasi-Kuliner-Jowo/blob/main/README.md',
    },
    {
      image: Diagnoseit,
      title: 'Diagnose.it',
      description:
        'Application to help diagnose minor illnesses. This application diagnoses by entering the symptoms of the disease.',
      stack: [],
      sourceCodeLink: 'https://github.com/raihaniqbalpasya/19104030_Raihan_PermogramanGUI/tree/main/Tugas%20Besar',
      previewLink:
        'https://github.com/raihaniqbalpasya/19104030_Raihan_PermogramanGUI/blob/main/Tugas%20Besar/README.md',
    },
  ];
  return (
    <>
      <img
        id="projects"
        width={'100%'}
        src={WaveUpVector}
        alt=""
        style={{
          position: 'relative',
          bottom: '-5px',
          pointerEvents: 'none',
          userSelect: 'none',
          marginTop: '75px',
          zIndex: '-1',
        }}
      />
      <Box sx={{ bgcolor: '#ffffff' }}>
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ width: 'fit-content', borderLeft: '6px solid #ff4500', paddingLeft: '5px' }}>
            Project Experiences
          </h1>
          <Grid container spacing={2}>
            {projectList.map((item) => {
              return (
                <Grid item xs={12} sm={6} md={4} sx={{ height: 'auto' }}>
                  <Box sx={{ height: '100%', border: 2, borderColor: 'primary.main', borderRadius: '8px' }}>
                    <Box
                      className={`${style['project-card']}`}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        padding: '14px',
                        position: 'relative',
                      }}
                    >
                      <Box className={`${style['show-card-detail']}`}>
                        <div>View more project details</div>
                        <Link to={item.previewLink} target="_blank" rel="noopener noreferrer">
                          <Button variant="outlined" size="small" color="primary" sx={{ borderRadius: '100px' }}>
                            View Project
                          </Button>
                        </Link>
                        <Link to={item.sourceCodeLink} target="_blank" rel="noopener noreferrer">
                          <Button variant="contained" size="small" color="primary" sx={{ borderRadius: '100px' }}>
                            Source Code
                          </Button>
                        </Link>
                      </Box>
                      <img
                        width={'100%'}
                        height={200}
                        src={
                          item.image ||
                          'https://img.freepik.com/free-vector/application-learn-languages_23-2148624202.jpg'
                        }
                        alt=""
                        style={{ borderRadius: '8px', objectFit: 'contain' }}
                      />
                      <h3 style={{ margin: 0 }}>{item.title}</h3>
                      <div>{item.description}</div>
                      {/* <span style={{ display: 'flex', justifyContent: 'end', gap: '5px' }}>
                        <img
                          width={25}
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png"
                          alt=""
                        />
                        <img
                          width={25}
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png"
                          alt=""
                        />
                      </span> */}
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
      <img
        width={'100%'}
        src={WaveDownVector}
        alt=""
        style={{
          position: 'relative',
          top: '-5px',
          pointerEvents: 'none',
          userSelect: 'none',
          zIndex: '-1',
        }}
      />
    </>
  );
}
