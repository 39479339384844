import { Box, Button, Container, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RDP_Logo from '../assets/images/RDP_Logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import style from './HomeStyles.module.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function Navbar() {
  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);
  const [scrollPosition, setScrollPosition] = useState(window.screenY);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    // Set initial scroll position
    setScrollPosition(window.scrollY);

    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <br />
      <div
        style={{
          position: 'sticky',
          top: '0',
          zIndex: 100,
          backgroundColor: scrollPosition >= 70 ? '#ffffff' : null,
          boxShadow: scrollPosition >= 70 ? '0 2px 4px rgba(0, 0, 0, 0.1)' : null,
        }}
      >
        <Container>
          <Grid
            container
            sx={{
              height: '70px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '14px',
                fontSize: '24px',
                fontWeight: 'bold',
                color: '#ff4500',
              }}
            >
              <img height={40} src={RDP_Logo} alt="Logo" />
              <IconButton
                color="primary"
                onClick={() => setOpenMobileMenu(true)}
                sx={{ display: { xs: 'flex', md: 'none' } }}
              >
                <MenuIcon sx={{ fontSize: '30px' }} />
              </IconButton>
            </Grid>

            <Grid
              item
              md={10}
              sx={{
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'end',
                alignItems: 'center',
                gap: 4,
                fontSize: '16px',
                fontWeight: '500',
                padding: '14px',
              }}
            >
              <HashLink smooth to={'#about'}>
                <span>About</span>
              </HashLink>
              <HashLink smooth to={'#expertise'}>
                <span>Expertise</span>
              </HashLink>

              <HashLink smooth to={'#projects'}>
                <span>Projects</span>
              </HashLink>

              <HashLink smooth to={'#publications'}>
                <span>Publications</span>
              </HashLink>

              <HashLink smooth to={'#contact'}>
                <span>Contact</span>
              </HashLink>

              <Link
                to="https://drive.google.com/file/d/120M4is0y-oppbdRbUITUlBgCFCvtYBvP/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="outlined" size="small" color="primary" sx={{}}>
                  Download CV
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </div>

      <Dialog
        fullScreen="xs"
        open={openMobileMenu}
        onClose={() => setOpenMobileMenu(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <img height={40} src={RDP_Logo} alt="Logo" />
          <IconButton color="primary" onClick={() => setOpenMobileMenu(false)}>
            <CloseIcon sx={{ fontSize: '30px' }} />
          </IconButton>
        </DialogTitle>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <HashLink smooth to={'#about'}>
            <Box
              onClick={() => setTimeout(() => setOpenMobileMenu(false), 500)}
              className={`${style['menu-item-mobile']}`}
              sx={{ py: 2, mx: 3, fontSize: '18px' }}
            >
              About
            </Box>
          </HashLink>

          <HashLink smooth to={'#expertise'}>
            <Box
              onClick={() => setTimeout(() => setOpenMobileMenu(false), 500)}
              className={`${style['menu-item-mobile']}`}
              sx={{ py: 2, mx: 3, fontSize: '18px' }}
            >
              Expertise
            </Box>
          </HashLink>

          <HashLink smooth to={'#projects'}>
            <Box
              onClick={() => setTimeout(() => setOpenMobileMenu(false), 500)}
              className={`${style['menu-item-mobile']}`}
              sx={{ py: 2, mx: 3, fontSize: '18px' }}
            >
              Projects
            </Box>
          </HashLink>

          <HashLink smooth to={'#publications'}>
            <Box
              onClick={() => setTimeout(() => setOpenMobileMenu(false), 500)}
              className={`${style['menu-item-mobile']}`}
              sx={{ py: 2, mx: 3, fontSize: '18px' }}
            >
              Publications
            </Box>
          </HashLink>

          <HashLink smooth to={'#contact'}>
            <Box
              onClick={() => setTimeout(() => setOpenMobileMenu(false), 500)}
              className={`${style['menu-item-mobile']}`}
              sx={{ py: 2, mx: 3, fontSize: '18px' }}
            >
              Contact
            </Box>
          </HashLink>

          <Link
            to="https://drive.google.com/file/d/120M4is0y-oppbdRbUITUlBgCFCvtYBvP/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '100%' }}
          >
            <Box sx={{ mx: 3 }}>
              <Button variant="outlined" size="medium" color="primary" sx={{ width: '100%' }}>
                Download CV
              </Button>
            </Box>
          </Link>
        </Box>
      </Dialog>
    </>
  );
}
