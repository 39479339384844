import { Container, Grid } from '@mui/material';
import React from 'react';

export default function Contact() {
  return (
    <Container id="contact">
      <Grid container spacing={2} sx={{ my: { xs: 8, sm: 14 } }}>
        <Grid item xs={12} sm={6}>
          <div style={{ width: 'fit-content' }}>
            <h2 style={{ fontWeight: 'normal', margin: 0 }}>For any inquiries or just to say hello</h2>
            <div style={{ fontSize: '52px', fontWeight: 'bold' }}>Contact Me</div>
            <div style={{ textAlign: 'end' }}>"Hope we can work together soon"</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1} sx={{ wordBreak: 'break-word' }}>
            <Grid item xs={6}>
              <h3>Phone</h3>
              <div>(+62) 858 4862 2580</div>
            </Grid>
            <Grid item xs={6}>
              <h3>Email</h3>
              <div>rizkidelagaprasetya@gmail.com</div>
            </Grid>
            <Grid item xs={6}>
              <h3>Address</h3>
              <div>Batam, Riau Islands, Indonesia</div>
            </Grid>
            <Grid item xs={6}>
              {/* <h3>Follow me on</h3>
              <div style={{ display: 'flex', gap: '10px' }}>
                <img
                  width={25}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png"
                  alt=""
                />
                <img
                  width={25}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png"
                  alt=""
                />
                <img
                  width={25}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png"
                  alt=""
                />
              </div> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
