import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material';
// import reportWebVitals from './reportWebVitals';


const theme = createTheme({
  palette: {
    primary: {
      main: '#ff4500',
    },
    secondary: {
      main: '#000000'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Default styles
          borderWidth: '2px',
          borderColor: '#ff4500',

          // Hover styles
          '&:hover': {
            borderWidth: '2px',
            borderColor: '#ff4500',
          },

          // Active styles
          '&:active': {
            borderWidth: '2px',
            borderColor: '#ff4500',
          },

          // Focus styles
          '&:focus': {
            borderWidth: '2px',
            borderColor: '#ff4500',
          },

          // Add other styles as needed
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme} >

    <App />
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
