import { Box, Button, Chip, Container, Grid } from '@mui/material';
import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

export default function Publications() {
  return (
    <Box id="publications" sx={{ py: 8 }}>
      <Container>
        <h1 style={{ width: 'fit-content', borderLeft: '6px solid #ff4500', paddingLeft: '5px' }}>Publications</h1>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            bgcolor: '#ffffff',
            padding: 2,
            mb: 2,
          }}
        >
          <div style={{ width: '100%' }}>
            <Chip label="Scientific Journal" size="small" color="primary" variant="outlined" />
            <div style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '4px', marginBottom: '4px' }}>
              Pengujian Usability Pada Website Kitabisa.com Menggunakan Metode System Usability Scale
            </div>
            <span>METHODIKA: Jurnal Teknisak Informatika dan Sistem Informasi || Vol. 9 No. 2 (2023)</span>
          </div>
          <Link to="https://doi.org/10.46880/mtk.v9i2.1942" target="_blank" rel="noopener noreferrer">
            <Box sx={{ display: 'flex', gap: '5px', color: 'primary.main' }}>
              <span>View Journal</span>
              <ArrowForwardIcon sx={{ fontSize: '20px' }} />
            </Box>
          </Link>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            bgcolor: '#ffffff',
            padding: 2,
            mb: 2,
          }}
        >
          <div style={{ width: '100%' }}>
            <Chip label="Scientific Journal" size="small" color="primary" variant="outlined" />
            <div style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '4px', marginBottom: '4px' }}>
              Memprediksi Tingkat Kepuasan Pelanggan Terhadap Kualitas Suatu Toko Menggunakan Metode Naïve Bayes
            </div>
            <span>METHODIKA: Jurnal Teknisak Informatika dan Sistem Informasi || Vol. 9 No. 2 (2023)</span>
          </div>
          <Link to="https://doi.org/10.46880/mtk.v9i2.1943" target="_blank" rel="noopener noreferrer">
            <Box sx={{ display: 'flex', gap: '5px', color: 'primary.main' }}>
              <span>View Journal</span>
              <ArrowForwardIcon sx={{ fontSize: '20px' }} />
            </Box>
          </Link>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            bgcolor: '#ffffff',
            padding: 2,
            mb: 2,
          }}
        >
          <div style={{ width: '100%' }}>
            <Chip label="Scientific Journal" size="small" color="primary" variant="outlined" />
            <div style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '4px', marginBottom: '4px' }}>
              Evaluasi Usability Aplikasi Satu Sehat Dengan Metode System Usability Scale
            </div>
            <span>METHODIKA: Jurnal Teknisak Informatika dan Sistem Informasi || Vol. 9 No. 2 (2023)</span>
          </div>
          <Link to="https://doi.org/10.46880/mtk.v9i2.1946" target="_blank" rel="noopener noreferrer">
            <Box sx={{ display: 'flex', gap: '5px', color: 'primary.main' }}>
              <span>View Journal</span>
              <ArrowForwardIcon sx={{ fontSize: '20px' }} />
            </Box>
          </Link>
        </Box>
      </Container>
    </Box>
  );
}
