import React, { Fragment, useEffect } from 'react';
import Navbar from '../components/Navbar';
import HeroBanner from '../components/HeroBanner';
import About from '../components/About';
import Expertise from '../components/Expertise';
import Projects from '../components/Projects';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import Publications from '../components/Publications';

export default function Home() {
  useEffect(() => {
    document.title = `Rizki Delaga Prasetya - Personal Blog`;
  }, []);

  return (
    <Fragment>
      <Navbar />
      <HeroBanner />
      <About />
      <Expertise />
      <Projects />
      <Publications />
      <Contact />
      <Footer />
    </Fragment>
  );
}
