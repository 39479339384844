import { Box } from '@mui/material';
import React from 'react';

export default function Footer() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        bgcolor: '#212121',
        color: '#ffffff',
      }}
    >
      <div>Copyright &#169; 2024 Rizki Delaga Prasetya</div>
    </Box>
  );
}
